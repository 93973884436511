import React, { FunctionComponent } from 'react';
import SelectInput from '../../../../components/molecules/Form/SelectInput/SelectInput';
import { DepartmentType } from '../../../../@types/Department/DepartmentType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';

type Props = {
  onDepartmentSelect: (data: DictValue<DepartmentType>) => void;
  placeholder: string;
  defaultValue?: DictValue<DepartmentType>;
  required?: boolean;
};

const DepartmentSelect: FunctionComponent<Props> = ({ onDepartmentSelect, placeholder, defaultValue, required = true }) => {
  return (
    <SelectInput
      name={'department'}
      label={'Department'}
      baseUrl={'/departments'}
      onChange={onDepartmentSelect}
      placeholder={placeholder}
      defaultValue={defaultValue}
      required={required}
    />
  );
};

export default DepartmentSelect;
