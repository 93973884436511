import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import EntityProvider from '../../../Context/EntityContext/EntityProvider';
import { EstimateVersionType } from '../../../@types/Estimate/EstimateType';
import { Loader } from '../../../legacy/CapeMorris/components';
import EstimateVersionDetailsInner from './components/EstimateVersionDetailsInner';

const EstimateVersionDetailsScreen: FunctionComponent = () => {
  let { id } = useParams<{ id: string }>();

  return (
    <EntityProvider<EstimateVersionType> url={`/api/estimate_versions/${id}`}>
      {({ loading, entity }) => (
        <>
          {loading && <Loader />}
          {entity && <EstimateVersionDetailsInner version={entity} />}
        </>
      )}
    </EntityProvider>
  );
};
export default EstimateVersionDetailsScreen;
