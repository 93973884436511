import { EntityType } from '../@types/Entity/EntityType';

class IriHelper {
  static iriToId(iri: string | EntityType | undefined | null) {
    if (!iri) {
      return undefined;
    }
    if (typeof iri === 'object' && iri['@id']) {
      return iri['@id'].split('/').pop();
    } else if (typeof iri === 'string') {
      return iri.split('/').pop();
    }
  }
}

export default IriHelper;
