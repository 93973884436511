import React, { FunctionComponent, useContext } from 'react';
import * as Styled from './AddResourceButton.styled';
import PlusIcon from '../../../../Icons/Plus.icon';
import { ModalContext } from '../../../../Context/ModalContext/ModalContext';
import ResourceModal from './ResourceModal';
import { Icon } from '../../../atoms/Icon/Icon';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import { FileContextType } from '../../../../@types/File/File';

type Props = {
  subject: string;
  subjectId: string | number;
  onCreate: (resource: ResourceType) => void;
  context?: FileContextType;
};

const AddResourceButton: FunctionComponent<Props> = ({ subject, subjectId, onCreate, context }) => {
  const { showModal } = useContext(ModalContext);

  const addResource = () => {
    showModal({
      title: 'Resources',
      body: <ResourceModal subject={subject} subjectId={subjectId} onCreate={onCreate} context={context} />,
      width: 42,
    });
  };
  return (
    <Styled.Container>
      <Styled.Button onClick={() => addResource()} type={'button'}>
        <Icon size={1.2}>
          <PlusIcon />
        </Icon>
      </Styled.Button>
    </Styled.Container>
  );
};

export default AddResourceButton;
