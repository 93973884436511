import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import DynamicSelectInput from '../../../molecules/Form/SelectInput/DynamicSelectInput';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { ProjectType } from '../../../../@types/Project/Project';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import { color, fontWeight } from '../../../../styles/Variables';
import { PiTrashBold } from 'react-icons/pi';
import styled from 'styled-components';
import ProjectOption from 'components/molecules/Projects/ProjectOption/ProjectOption';
import HiddenInput from '../../../atoms/Form/HiddenInput/HiddenInput';

type ProjectOption = Partial<ProjectType> & { _id: string };

const EstimateFormProjects: FunctionComponent = () => {
  const { control, setValue } = useFormContext();
  const [clientId, setClientId] = useState<number | undefined>();
  const {
    fields: projects,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'projects',
    keyName: '_id',
  });
  const watchedProjects = useWatch({ name: 'projects' });

  useEffect(() => {
    if (!watchedProjects || watchedProjects.length === 0) {
      setClientId(undefined);
      return;
    }
    if (clientId) {
      return;
    }
    if (watchedProjects.length > 0) {
      setClientId(watchedProjects[0].client.id);
    }
  }, [watchedProjects, clientId]);
  const appendProject = (project: DictValue<ProjectType>) => {
    if (!project) {
      return;
    }
    append(project.meta);
    setClientId(project.meta?.client.id);
    setValue('__project', undefined);
  };

  return (
    <>
      <DynamicSelectInput
        name={'__project'}
        baseUrl={'/projects'}
        label={'Add project'}
        onChange={appendProject}
        optionComponent={ProjectOption}
        defaultOptions={false}
        baseUrlParams={{
          'client.id': clientId,
        }}
      />
      {projects.map((project: ProjectOption, index) => (
        <StyledProject key={project['_id']}>
          <Colored style={{ fontWeight: fontWeight.bold }}>#{project.id}</Colored> {project.name}
          <StyledDelete onClick={() => remove(index)}>
            <PiTrashBold />
          </StyledDelete>
          <HiddenInput name={`projects.${index}.id`} value={project.id} />
          <HiddenInput name={`projects.${index}.name`} value={project.name} />
        </StyledProject>
      ))}
    </>
  );
};

const StyledDelete = styled.div`
  cursor: pointer;
  transition: color 0.2s ease;
  justify-self: flex-end;
  margin-left: auto;
  margin-top: 0.3rem;

  &:hover {
    color: ${color.semantic.error[100]};
  }
`;

const StyledProject = styled.div`
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  border: 1px solid ${color.neutral[50]};
  padding: 0.5rem 1rem;
  border-radius: 0.3em;
`;

export default EstimateFormProjects;
