import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import Iframe from '../../legacy/CapeMorris/components/Iframe/Iframe';
import MyInventorySubmodule from './submodules/MyInventorySubmodule';
import { Helmet } from 'react-helmet';

const ProfileModule: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="" element={<MyInventorySubmodule />}></Route>
      <Route
        path="notifications"
        element={<Iframe src={`${process.env.REACT_APP_FCAPE_LEGACY ?? '/'}/my-profile/notification`} title="Notifications" />}
      ></Route>
    </Routes>
  );
};

export default ProfileModule;
