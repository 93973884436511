import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import * as Styled from './Dashboard.styled';
import { Grid } from '../../../atoms/Grid/Grid';
import MyTasks from '../../../organisms/Projects/Home/Dashboard/cards/MyTasks';
import AssignedTasks from '../../../organisms/Projects/Home/Dashboard/cards/AssignedTasks';
import MyProjects from '../../../organisms/Projects/Home/Dashboard/cards/MyProjects';
import HomeOffices from '../../../organisms/Projects/Home/Dashboard/cards/HomeOffices';
import HotNews from '../../../organisms/Projects/Home/Dashboard/cards/HotNews';
import Absences from '../../../organisms/Projects/Home/Dashboard/cards/Absences';
import PrivateNote from '../../../organisms/Projects/Home/Dashboard/cards/PrivateNote';
import Notification from '../../../molecules/Projects/Dashboard/Notification/Notification';
import { FeedEntryType } from '../../../../@types/FeedEntry/FeedEntryType';
import { useNavigate, useOutletContext } from 'react-router-dom';
import api from '../../../../services/api';
import Container from '../../../atoms/Container/Container';
import { Helmet } from 'react-helmet';
import InOffice from '../../../organisms/Projects/Home/Dashboard/cards/InOffice';
import TimeshetSummary from '../../../organisms/Projects/Home/Dashboard/cards/TimeshetSummary';

type Props = {};

const Dashboard: FunctionComponent<Props> = ({}) => {
  const navigate = useNavigate();
  const [data]: any = useOutletContext();

  const [lastWallEntry, setLastWallEntry] = useState<FeedEntryType | undefined>();

  const getLastWallEntry = useCallback(async () => {
    await api.get('/feed_entries?status=active').then((response) => {
      setLastWallEntry(response.data['hydra:member'][0]);
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('Dashboard::lastVisited', '/projects/dashboard');
    void getLastWallEntry();
  }, []);

  return (
    <Container $wide>
      <Helmet>
        <title>Dashboard | F.CAPE</title>
      </Helmet>
      <Styled.DashboardStyled>
        {data && data?.newestFeedEntryTitle && data.unseenFeedEntries > 0 && (
          <Notification title={data?.newestFeedEntryTitle} onClick={() => navigate('../feed')} />
        )}
        <Grid spacing={2.4}>
          <Styled.Column $desktop={9}>
            <MyTasks />
            <AssignedTasks />
            <MyProjects />
            <PrivateNote />
          </Styled.Column>
          <Styled.Column $desktop={3}>
            <TimeshetSummary data={data?.timesheetSummary} />
            <HotNews feedEntry={lastWallEntry} />
            {data && <InOffice inOfficeToday={data.inOfficeToday} />}
            <HomeOffices homeOfficeToday={data?.homeOfficeToday} homeOfficeUpcoming={data?.homeOfficeUpcoming} />
            <Absences absencesToday={data?.absencesToday} absencesUpcoming={data?.absencesUpcoming} />
          </Styled.Column>
        </Grid>
      </Styled.DashboardStyled>
    </Container>
  );
};

export default Dashboard;
