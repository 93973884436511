import { EntityType } from '../Entity/EntityType';
import { ClientType } from '../Project/Client/ClientType';
import { MoneyType } from '../Money/MoneyType';
import { ProjectType } from '../Project/Project';
import { BasicEmployeeType } from '../Employee/EmployeeType';
import { DictValue } from '../Dictionary/DictValue';

export const ESTIMATE_SUBJECT = 'Estimate';
export const ESTIMATE_ACCEPT_PROOF_SUBJECT = 'Estimate::Proof';
export const ESTIMATE_VERSION_SUBJECT = 'EstimateVersion';

export type EstimateType = EntityType & {
  title: string;
  status: 'draft' | 'in_progress' | 'accepted' | 'rejected' | 'canceled';
  description: string;
  client: Partial<ClientType>;
  currentVersion: Partial<EstimateVersionType>;
  versions: EstimateVersionType[];
  currency: string;
  projects: Partial<ProjectType>[];
  createdBy: BasicEmployeeType;
  createdAt: string;
};

export type EstimateVersionType = EntityType & {
  title: string;
  description: string;
  status: 'current' | 'alternative' | 'old';
  total: MoneyType;
  createdAt: string;
  createdBy: BasicEmployeeType;
  currency: string;
  estimate: {
    '@id': string;
  };
};

export const EstimateStatusDict: DictValue<EstimateType['status']>[] = [
  { value: 'draft', label: 'Draft' },
  { value: 'in_progress', label: 'In progress' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'canceled', label: 'Canceled' },
];

export const EstimateVersionStatusDict: DictValue<EstimateVersionType['status']>[] = [
  { value: 'current', label: 'Current' },
  { value: 'alternative', label: 'Alternative' },
  { value: 'old', label: 'Old' },
];
