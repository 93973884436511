import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { ESTIMATE_VERSION_SUBJECT, EstimateType, EstimateVersionStatusDict, EstimateVersionType } from '../../../../@types/Estimate/EstimateType';
import useApi from '../../../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import Form from '../../../molecules/Form/Form';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import PageTop from '../../../molecules/Page/PageTop';
import Button from '../../../atoms/Button/Button';
import { Card } from '../../../atoms/Card/Card';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import RichTextInput from '../../../molecules/Form/RichTextInput/RichTextInput';
import MoneyInput from '../../../molecules/Form/MoneyInput/MoneyInput';
import StaticSelectInput from '../../../molecules/Form/SelectInput/StaticSelectInput';
import HiddenInput from '../../../atoms/Form/HiddenInput/HiddenInput';
import Resources from '../../../organisms/Resources/Resources';

type Props = {
  version?: EstimateVersionType;
  onChange?: (version: EstimateVersionType) => void;
  currency?: string;
  estimateIri?: string;
  estimateTitle?: string;
};

const EstimateVersionForm: FunctionComponent<Props> = ({ version, onChange, currency, estimateIri, estimateTitle }) => {
  const isEdit = !!version;
  const { put, post } = useApi();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (data: Partial<EstimateType>) => {
      if (isEdit && version) {
        put(`${version['@id']}`, data).then((response) => {
          onChange && onChange(response.data);
          navigate(`/finances/estimate-versions/${response.data.id}/show`);
        });
      } else {
        post<EstimateVersionType>('/api/estimate_versions', data).then((response) => {
          onChange && onChange(response.data);
          navigate(`/finances/estimate-versions/${response.data.id}/show`);
        });
      }
    },
    [isEdit, version],
  );

  useEffect(() => {
    if (!version && !estimateIri) {
      navigate('/finances/estimates');
    }
  }, [version, estimateIri]);

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={
        version ?? {
          estimate: estimateIri,
        }
      }
    >
      <Grid spacing={4}>
        <GridItem $desktop={12}>
          <PageTop
            heading={isEdit ? 'Edit version' : `Create version of ${estimateTitle}`}
            rightColumn={
              <Grid $simple spacing={4}>
                <Button type={'submit'} $text={'Save'} />
                <Button
                  $text={'Cancel'}
                  kind={'secondary'}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </Grid>
            }
          />
        </GridItem>
        <GridItem $desktop={6}>
          <Grid spacing={4}>
            <GridItem $desktop={12}>
              <Card>
                <CardHeading heading={'Basic information'} />
                <HiddenInput name={'estimate'} value={estimateIri} />
                <Grid spacing={2}>
                  <GridItem $desktop={12}>
                    <TextInput name={'title'} label={'Title'} required />
                  </GridItem>

                  <GridItem $desktop={12}>
                    <MoneyInput name={'total'} label={'Total'} currency={version?.currency ?? currency ?? 'PLN'} required />
                  </GridItem>
                  <GridItem $desktop={12}>
                    <StaticSelectInput label={'Status'} name={'status'} options={EstimateVersionStatusDict} required />
                  </GridItem>
                </Grid>
              </Card>
            </GridItem>
            <GridItem $desktop={12}>
              <Card>
                <CardHeading heading={'Description'} />
                <Grid spacing={2}>
                  <GridItem $desktop={12}>
                    <RichTextInput name={'description'} label={'Description'} />
                  </GridItem>
                </Grid>
              </Card>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem $desktop={6}>
          {version && (
            <Card>
              <CardHeading heading={'Attachments'} />
              <Resources disableCreation subject={ESTIMATE_VERSION_SUBJECT} subjectId={version.id} context={'finances'} />
            </Card>
          )}
        </GridItem>
      </Grid>
    </Form>
  );
};
export default EstimateVersionForm;
