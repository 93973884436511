import React, { FunctionComponent, useState } from 'react';
import { Grid, GridItem } from 'components/atoms/Grid/Grid';
import ModalForm from '../../Form/ModalForm/ModalForm';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import HiddenInput from '../../../atoms/Form/HiddenInput/HiddenInput';
import Dropzone from '../../../molecules/Dropzone/Dropzone';
import useApi from '../../../../hooks/useApi';
import useModal from '../../../../hooks/useModal';
import { FileContextType } from '../../../../@types/File/File';

type Props = {
  subject: string;
  subjectId: string | number;
  resource?: ResourceType;
  onCreate: (resource: ResourceType) => void;
  context?: FileContextType;
};

const ResourceModal: FunctionComponent<Props> = ({ subject, subjectId, resource, onCreate, context }) => {
  const { post } = useApi();
  const [_resource] = useState<ResourceType | undefined>(resource);
  const isEdit = !!_resource;
  const { hideModal } = useModal();

  const handleChange = (resource: ResourceType) => {
    onCreate(resource);
  };

  const handleUpload = (uuid: string) => {
    post<ResourceType>(
      '/resources',
      {
        fileUuid: uuid,
        subject: subject,
        subjectId: subjectId.toString(),
      },
      true,
    ).then(({ data }) => {
      handleChange(data);
      hideModal();
    });
  };

  return (
    <ModalForm
      basePath={isEdit ? _resource['@id'] : '/api/resources'}
      buttonText={isEdit ? 'Save' : 'Create'}
      defaults={_resource}
      onChange={handleChange}
      errorMessage={'Provide a valid link to Sharepoint folder, Mattermost channel, Figma project or Keynote presentation.'}
    >
      <Grid spacing={1.5} $simple>
        <TextInput name={'url'} placeholder={'Paste link to resource'} label={'URL'} disabled={isEdit} />
        <TextInput name={'name'} defaultValue={resource?.name} label="Name is optional" placeholder={_resource?.name} />

        <HiddenInput name={'subject'} value={subject} />
        <HiddenInput name={'subjectId'} value={subjectId.toString()} />
        <GridItem $desktop={12}>
          <Dropzone maxFiles={1} onUpload={handleUpload} text={'Alternatively you can upload file from your computer'} context={context ?? 'project'} />
        </GridItem>
      </Grid>
    </ModalForm>
  );
};

export default ResourceModal;
