import React, { FunctionComponent } from 'react';
import Table from '../../../../organisms/Table/Table';
import { properties } from '../../../Estimates/EstimatesListScreen.properties';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { useNavigate } from 'react-router-dom';

const EstimatesView: FunctionComponent = () => {
  const { entity } = useEntityContext();
  const navigate = useNavigate();
  return (
    <Table
      globalFilters={{
        'projects.id': entity.id,
      }}
      url={'/estimates'}
      context="/api/estimates|Project"
      properties={properties}
      onItemClick={(item) => {
        navigate(`/finances/estimates/${item.id}/show`);
      }}
    />
  );
};
export default EstimatesView;
