import React, { FunctionComponent, useState } from 'react';
import DropdownButton from '../../../molecules/DropdownButton/DropdownButton';
import { EstimateStatusDict, EstimateType } from '../../../../@types/Estimate/EstimateType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { PiArrowRight, PiArrowRightBold } from 'react-icons/pi';
import { DropdownMenuItemType } from '../../../organisms/DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import useApi from '../../../../hooks/useApi';
import { useEntityContext } from '../../../../Context/EntityContext/EntityContext';

type Props = {
  estimate: EstimateType;
};

const EstimateStatusButton: FunctionComponent<Props> = ({ estimate }) => {
  const { post } = useApi();
  const { replace } = useEntityContext();
  const [waiting, setWaiting] = useState(false);
  const changeStatus = async (status: string) => {
    setWaiting(true);
    return post(`/estimates/${estimate.id}/change-status`, { status })
      .then((r) => {
        replace(r.data);
        setWaiting(false);
      })
      .catch(() => {
        setWaiting(false);
      });
  };

  const actions: DropdownMenuItemType[] = EstimateStatusDict.filter((e) => e.value !== estimate.status && e.value !== nextAction(estimate.status)).map(
    (dict: DictValue) => ({
      text: dict.label,
      icon: <PiArrowRight />,
      onClick: () => changeStatus(dict.value),
    }),
  );

  return (
    <DropdownButton
      $icon={<PiArrowRightBold />}
      $text={translateStatus(nextAction(estimate.status))}
      dropdownMenuItems={actions}
      triggerType={'click'}
      waiting={waiting}
      onClick={() => changeStatus(nextAction(estimate.status))}
    />
  );
};

const nextAction = (status: EstimateType['status']): EstimateType['status'] => {
  switch (status) {
    case 'draft':
      return 'in_progress';
    case 'in_progress':
      return 'accepted';
    case 'accepted':
      return 'in_progress';
    case 'rejected':
      return 'in_progress';
    case 'canceled':
      return 'in_progress';
    default:
      return 'in_progress';
  }
};

const translateStatus = (status: EstimateType['status']): string => {
  return EstimateStatusDict.find((dict: DictValue) => dict.value === status)?.label || status;
};

export default EstimateStatusButton;
