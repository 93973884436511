import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../../../../../components/atoms/Grid/Grid';
import { Card } from '../../../../../../components/atoms/Card/Card';
import CardHeading from '../../../../../../components/molecules/CardHeading/CardHeading';
import DatePicker from '../../../../../../components/molecules/Form/DatePicker/DatePicker';
import NumberInput from '../../../../../../components/molecules/Form/NumberInput/NumberInput';
import { SwitchInput } from '../../../../../../components/molecules/Form/SwitchInput/SwitchInput';
import MoneyInput from '../../../../../../components/molecules/Form/MoneyInput/MoneyInput';

type Props = {};

const EmployeeDetailsForm: FunctionComponent<Props> = (props) => {
  return (
    <GridItem $desktop={6}>
      <Card>
        <CardHeading heading={'Other details'} />
        <Grid spacing={2}>
          <GridItem $desktop={4}>
            <DatePicker name={'firstDay'} label={'First day'} />
          </GridItem>
          <GridItem $desktop={4}>
            <NumberInput name={'fullTimeEquivalent'} label={'FTE'} float />
          </GridItem>
          <GridItem $desktop={4}>
            <MoneyInput name={'maximumCostsAcceptanceValue'} label={'Max. PO acceptance value'} currency={'PLN'} />
          </GridItem>
          <GridItem>
            <SwitchInput name={'specialMail'} label={'Magic Mail'} />
          </GridItem>
          <GridItem>
            <SwitchInput name={'timesheetDisabled'} label={'Timesheet disabled'} />
          </GridItem>
          <GridItem>
            <SwitchInput name={'remote'} label={'Remote employee'} />
          </GridItem>
        </Grid>
      </Card>
    </GridItem>
  );
};

export default EmployeeDetailsForm;
