import React, { FunctionComponent } from 'react';
import { ESTIMATE_VERSION_SUBJECT, EstimateType, EstimateVersionType } from '../../../../@types/Estimate/EstimateType';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { Card } from '../../../atoms/Card/Card';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import PageTop from '../../../molecules/Page/PageTop';
import Heading from '../../../atoms/Heading';
import Text from '../../../atoms/Text';
import useEntity from '../../../../hooks/useEntity';
import { fontWeight } from '../../../../styles/Variables';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import EstimateVersionStatusTag from '../../../molecules/Estimate/EstimateVersionStatusTag';
import moneyAsString from '../../../../helpers/moneyAsString';
import DateString from '../../../atoms/DateString/DateString';
import Person from '../../../molecules/Person/Person';
import RichText from '../../../atoms/RichText/RichText';
import EstimateStatusTag from '../../../molecules/Estimate/EstimateStatusTag';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import Button from '../../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import ResourceDropzone from '../../../organisms/Resources/ResourceDropzone';
import Resource from '../../../organisms/Resources/components/Resource';
import Resources from '../../../organisms/Resources/Resources';

type Props = {
  version: EstimateVersionType;
};

const EstimateVersionDetailsInner: FunctionComponent<Props> = ({ version }) => {
  const { entity: estimate } = useEntity<EstimateType>(version.estimate['@id']);
  const navigate = useNavigate();
  const editable = estimate && estimate?.status !== 'accepted';
  return (
    <Grid spacing={4}>
      <GridItem $desktop={12}>
        <PageTop
          heading={
            <div>
              <Heading>{version.title}</Heading>
              <Text size={'l'}>
                version of <span style={{ fontWeight: fontWeight.bold }}>{estimate?.title}</span>
              </Text>
            </div>
          }
          showBackButton
          backBtnLink={estimate ? `/finances/estimates/${estimate?.id}/show` : undefined}
          rightColumn={<>{editable && <Button $text={'Edit'} onClick={() => navigate(`/finances/estimate-versions/${version.id}/edit`)} />}</>}
        />
      </GridItem>
      <GridItem $desktop={6}>
        <Grid spacing={4}>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Version'} rightElement={<EstimateVersionStatusTag status={version.status} />} />
              <Grid spacing={2}>
                <SingleDetail $desktop={6} title={'Title'}>
                  {version.title}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Total'}>
                  {moneyAsString(version.total)}
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Created by'}>
                  <Person person={version.createdBy} />
                </SingleDetail>
                <SingleDetail $desktop={6} title={'Created at'}>
                  <DateString input={version.createdAt} />
                </SingleDetail>
                <SingleDetail $desktop={12} title={'Description'}>
                  <RichText content={version.description} />
                </SingleDetail>
              </Grid>
            </Card>
          </GridItem>
          <GridItem $desktop={12}>
            {estimate && (
              <Card>
                <CardHeading heading={'Estimate details'} rightElement={<EstimateStatusTag status={estimate?.status} />} />
                <Grid spacing={2}>
                  <SingleDetail title="Title" $desktop={6}>
                    {estimate.title}
                  </SingleDetail>
                  <SingleDetail title="Currency" $desktop={6}>
                    {estimate.currency}
                  </SingleDetail>
                  <SingleDetail title="Client" $desktop={12}>
                    {estimate.client?.name}
                  </SingleDetail>
                  <SingleDetail title="Projects" $desktop={12}>
                    {estimate.projects.map((p) => (
                      <span key={p.id}>
                        <Colored style={{ fontWeight: fontWeight.bold }}>#{p.id}</Colored>
                        {p.name}
                        <br />
                      </span>
                    ))}
                  </SingleDetail>
                  <SingleDetail title={'Created by'} $desktop={6}>
                    <Person person={estimate.createdBy} />
                  </SingleDetail>
                  <SingleDetail title={'Created at'} $desktop={6}>
                    <DateString input={estimate.createdAt} />
                  </SingleDetail>
                </Grid>
              </Card>
            )}
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem $desktop={6}>
        <Card>
          <CardHeading heading={'Attachments'} />
          <Resources disableCreation={!editable} subject={ESTIMATE_VERSION_SUBJECT} subjectId={version.id} context={'finances'} />
        </Card>
      </GridItem>
    </Grid>
  );
};
export default EstimateVersionDetailsInner;
