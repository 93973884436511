import React, { FunctionComponent, useEffect, useState } from 'react';
import { Command } from 'cmdk';
import * as Styled from './Commander.styled';
import { PiBank, PiCalendarHeart, PiKanban, PiListChecks } from 'react-icons/pi';
import CommanderItem from './CommanderItem';
import api from '../../../services/api';

export type Item<M extends string | number = string | number> = {
  icon?: FunctionComponent;
  value: string;
  type: string;
  keywords?: string[];
  metadata?: Record<M, string | number | boolean | null>;
  priority?: number;
  path?: string;
};

const Commander: FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [items, setItems] = useState<Record<string, Item[]>>({
    'Quick links': [
      {
        type: 'Page',
        icon: PiListChecks,
        value: 'Projects',
        keywords: ['project', 'account', 'client'],
        path: '/projects/list',
      },
      {
        type: 'Page',
        icon: PiCalendarHeart,
        value: 'Timesheet',
        keywords: ['timesheet'],
        path: '/projects/timesheets',
      },
      {
        type: 'Page',
        icon: PiKanban,
        value: 'Workload',
        keywords: [],
        path: '/projects/workload',
      },
      {
        type: 'Page',
        icon: PiBank,
        value: 'Finances',
        keywords: ['costs', 'incomes', 'purchase', 'vendor'],
        path: '/finances',
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
      if (e.key === 'Escape') {
        e.preventDefault();
        setOpen(false);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    setLoading(true);
    const t = setTimeout(() => {
      api.get<Item[]>('/search', { params: { search } }).then((response) => {
        setItems((i) => ({ ...i, 'Best matches': response.data }));
        setLoading(false);
      });
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [search]);

  return (
    <Styled.Container $open={open}>
      <Styled.InnerContainer>
        <div className="raycast">
          <Command value={value} onValueChange={(v) => setValue(v)}>
            <div cmdk-raycast-top-shine="" />
            <Command.Input onValueChange={setSearch} ref={inputRef} autoFocus placeholder="Search for apps and commands..." />
            <hr cmdk-raycast-loader="" />
            <Command.List>
              <Command.Empty>No results found.</Command.Empty>
              {Object.keys(items).map((key, index) => (
                <Command.Group heading={key} key={`${key}-${index}`}>
                  {items[key].map((item, index) => (
                    <CommanderItem type={item.type} onSelect={() => setOpen(false)} item={item} key={`${key}-${item.value}-${index}`} />
                  ))}
                </Command.Group>
              ))}
            </Command.List>

            <div cmdk-raycast-footer="">
              <div style={{ display: 'flex' }}>
                {loading && (
                  <>
                    <span>Loading data...</span>
                  </>
                )}
              </div>
              <button cmdk-raycast-open-trigger="">
                Open Application
                <kbd>↵</kbd>
              </button>
            </div>
          </Command>
        </div>
      </Styled.InnerContainer>
    </Styled.Container>
  );
};

export default Commander;
