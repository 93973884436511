import { TableProperties } from '../../../../@types/Table/TableProperty';
import { CostType } from '../../../../@types/Finances/Cost/CostType';
import DateTemplate from '../../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import MoneyTemplate from '../../../molecules/CellTemplates/common/MoneyTemplate/MoneyTemplate';
import FinanceIdTemplate from '../../../molecules/CellTemplates/Finances/FinanceIdTemplate/FinanceIdTemplate';
import FinanceItemInfoTemplate from '../../../molecules/CellTemplates/Finances/FinanceItemInfoTemplate/FinanceItemInfoTemplate';
import ContractorValueTaxTemplate from '../../../molecules/CellTemplates/Finances/ContractorValueTaxTemplate/ContractorValueTaxTemplate';
import { PiBankBold } from 'react-icons/pi';
import React from 'react';

export const properties: TableProperties<CostType> = [
  {
    name: 'id',
    visibleName: 'ID',
    template: FinanceIdTemplate,
    sortable: true,
  },
  {
    name: 'number',
    visibleName: 'Number',
    template: FinanceItemInfoTemplate,
    sortable: true,
    templateOptions: {
      chips: [
        {
          display: () => 'Confidential',
          condition: (item: CostType) => item.confidential,
        },
        {
          display: () => 'Internal',
          condition: (item: CostType) => item.internal,
        },
        {
          icon: () => PiBankBold,
          display: () => 'Exportable',
          color: () => 'information',
          title: () => 'Bank export enabled',
          condition: (item: CostType) => item.bankExportEnabled,
        },
      ],
      textProperties: {
        size: 'l',
        bold: true,
      },
    },
  },
  {
    name: 'contractor',
    visibleName: 'Vendor',
    template: ContractorValueTaxTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        maxWidth: '15rem',
      },
    },
  },
  {
    name: 'postedAt',
    visibleName: 'Posting date',
    template: DateTemplate,
    sortable: true,
  },
  {
    name: 'paymentDue',
    visibleName: 'Payment date',
    template: DateTemplate,
    sortable: true,
  },
  {
    name: 'amountNet',
    visibleName: 'Net',
    template: MoneyTemplate,
    sortable: 'amountNet.value',
    justifyContent: 'flex-end',
  },
  {
    name: 'amountGross',
    visibleName: 'Gross',
    template: MoneyTemplate,
    sortable: 'amountGross.value',
    justifyContent: 'flex-end',
  },
  {
    name: 'liabilitiesPln',
    visibleName: 'Liabilities',
    template: MoneyTemplate,
    justifyContent: 'flex-end',
    sortable: 'liabilitiesPln.value',
    templateOptions: {
      colored: true,
      textProperties: {
        bold: true,
        color: 'primary',
      },
    },
  },
];
