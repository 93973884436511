import React, { FunctionComponent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import PlusIcon from '../../../../Icons/Plus.icon';
import TablePage from '../../../organisms/TablePage/TablePage';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import CostListExtendedRow from '../../../organisms/CostListExtendedRow/CostListExtendedRow';
import { properties } from './CostsInboxList.properites';
import { filters } from '../../Costs/CostsList/CostsList.filters';

const CostsInboxList: FunctionComponent = () => {
  let { pathname } = useLocation();
  let navigate = useNavigate();
  const { can } = React.useContext(AuthContext);

  const CreateAction = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={'auto'}>
          <Button $text={'New cost'} kind={'primary'} onClick={() => navigate('/finances/costs/costs/create')} $icon={<PlusIcon />} />
        </GridItem>
      </Grid>
    );
  };

  const CurrencyButton = () => {
    return <Grid spacing={2} $align={'center'}></Grid>;
  };

  return (
    <TablePage
      url={'/costs-inbox'}
      context={'/api/costs|CostsInbox'}
      headingRight={
        <Grid spacing={2}>
          <GridItem>
            <CurrencyButton />
          </GridItem>
          {can('FINANCES.COSTS.CAN_MANAGE') && (
            <GridItem>
              <CreateAction />
            </GridItem>
          )}
        </Grid>
      }
      properties={properties}
      filters={[
        ...filters,
        {
          type: 'switch',
          name: 'showAll',
          label: 'Show all',
          gridProps: {
            $desktop: 2,
          },
        },
      ]}
      heading={'Inbox'}
      onCreate={() => navigate('/finances/costs/costs/create')}
      globalFilters={{ status: 'pending', _archived: 0 }}
      extendedRow={CostListExtendedRow}
      itemLink={(item) => `${pathname}/${item.id}/show`}
    />
  );
};

export default CostsInboxList;
