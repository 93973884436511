import React from 'react';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { Container, Welcome } from './user-indicator.styled';
import Avatar from '../../../../components/atoms/Avatar/Avatar';
import useUser from '../../../../hooks/useUser';
import BetterChip from '../../../../components/atoms/Chips/BetterChip';

export const UserIndicator: React.FC = () => {
  const { state, isImpersonating } = React.useContext(AuthContext);
  const { context } = state;
  const user = useUser();
  return (
    <Container>
      {context !== 'employee' && <BetterChip>{context}</BetterChip>}
      {state.user && (
        <Welcome $isImpersonating={isImpersonating}>
          Hi, <span>{state.user.firstName}!</span>
        </Welcome>
      )}
      <Avatar employee={user} size={2.5} style={{ marginLeft: '0.7rem' }} />
    </Container>
  );
};
