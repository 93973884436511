import React, { CSSProperties, FunctionComponent, useEffect, useMemo, useState } from 'react';
import * as Styled from './Avatar.styled';
import api, { createFullApiUrl } from '../../../services/api';
import Tooltip from '../../molecules/Tooltip/Tooltip';
import Text from '../Text/Text';
import { TooltipArrowPosition } from '../../molecules/Tooltip/Tooltip.styled';
import { UserType } from '../../../@types/User/user';
import axios from 'axios';
import { useQuery } from 'react-query';

type Props = {
  size?: number;
  border?: boolean;
  style?: CSSProperties;
  tooltipPosition?: TooltipArrowPosition;
  employee:
    | UserType
    | {
        name: string;
        firstName?: string;
        lastName?: string;
        id?: string | number;
        employeeId?: number | string;
      };
};

const fetchPhoto = async (url?: string) => {
  if (!url) {
    return;
  }
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return api
    .get(url, { cancelToken: source.token })
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};

const Avatar: FunctionComponent<Props> = ({ employee, size = 2, border, style, tooltipPosition }) => {
  const name = employee.name ? employee.name : `${employee.lastName ?? ''} ${employee.firstName ?? ''}`;
  const id = employee.employeeId ?? employee.id;
  const [photoState, setPhotoState] = useState<'loading' | 'no-photo' | 'loaded'>('loading');
  const { data, isLoading, isError } = useQuery([id], () => fetchPhoto(`/api/employees/${id}/photo`), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false,
  });

  useEffect(() => {
    if (sessionStorage.getItem(`photo-${id}`)) {
      setPhotoState(sessionStorage.getItem(`photo-${id}`) as 'loaded' | 'no-photo');
      return;
    }
    if (data && typeof data === 'string') {
      sessionStorage.setItem(`photo-${id}`, 'loaded');
      setPhotoState('loaded');
    } else if (!isLoading && isError) {
      sessionStorage.setItem(`photo-${id}`, 'no-photo');
    }
  }, [data, isError, isLoading]);

  const initials = useMemo(() => {
    if (name && name.length > 0) {
      const [lastName, firstName] = name.split(' ');
      if (lastName && firstName) {
        return `${firstName[0]}${lastName[0]}`;
      } else {
        return name[0];
      }
    }
  }, [name]);

  return (
    <>
      {tooltipPosition ? (
        <Tooltip
          content={
            <Styled.TooltipWrapper>
              <Text color={'light'} size={'xs'}>
                {name}
              </Text>
            </Styled.TooltipWrapper>
          }
          trigger={
            <Styled.Avatar $border={border} $size={size} style={style}>
              {initials}
              {photoState === 'loaded' && <img src={createFullApiUrl(`/api/employees/${id}/photo`)} />}
            </Styled.Avatar>
          }
          arrowPosition={tooltipPosition}
        />
      ) : (
        <Styled.Avatar $border={border} $size={size} style={style}>
          {initials}
          {photoState === 'loaded' && <img src={createFullApiUrl(`/api/employees/${id}/photo`)} />}
        </Styled.Avatar>
      )}
    </>
  );
};

export default Avatar;
