import React, { FunctionComponent } from 'react';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../@types/Project/Project';
import ProjectFinancialData from '../../../../organisms/Projects/ProjectDetails/Overview/ProjectFinancialData/ProjectFinancialData';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import SubcostsListTable from '../../../../organisms/Tables/SubcostsListTable/SubcostsListTable';
import { Card } from '../../../../atoms/Card/Card';
import CardHeading from '../../../../molecules/CardHeading/CardHeading';
import Table from '../../../../organisms/Table/Table';
import * as Styled from './FinancesView.styled';
import Text from '../../../../atoms/Text';
import { parseDate } from '../../../../../helpers/date';
import { PurchaseOrderType } from '../../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import { properties as purchaseOrderProperties } from '../../../../organisms/Tables/PurchaseOrdersList/PurchaseOrders.properties';
import { properties as subincomesProperties } from '../../../Income/SubincomesList/SubincomesList.properties';
import { SubincomeType } from '../../../../../@types/Finances/Income/SubincomeType';

const FinancesView: FunctionComponent = () => {
  const { entity: project } = useEntityContext<ProjectType>();

  return (
    <Grid spacing={2}>
      <GridItem $desktop={12}>
        {project['hydra:reports'] && project['hydra:reports']['project-finances'] && (
          <Styled.InfoContainer>
            <Text size={'s'}>Last updated at: {parseDate(project['hydra:reports']['project-finances'], true)}</Text>
          </Styled.InfoContainer>
        )}
        <Card>
          <CardHeading heading={'Project financial overview'} />
          <ProjectFinancialData project={project} />
        </Card>
      </GridItem>
      <GridItem $desktop={12}>
        <Card>
          <CardHeading heading={'Costs'} />
          <SubcostsListTable
            globalFilters={{
              'project.id': project.id,
            }}
          />
        </Card>
      </GridItem>
      <GridItem $desktop={12}>
        <Card>
          <CardHeading heading={'Incomes'} />
          <Table<SubincomeType>
            context={'/api/subincomes|project'}
            url={'/subincomes'}
            properties={subincomesProperties}
            globalFilters={{
              'project.id': project.id,
            }}
            itemLink={(item) => `/finances/incomes/incomes/${item.income.id}/show`}
          />
        </Card>
      </GridItem>
      <GridItem $desktop={12}>
        <Card>
          <CardHeading heading={'Purchase orders'} />
          <Table<PurchaseOrderType>
            context={'/api/purchase_orders|project'}
            url={'/purchase_orders'}
            properties={purchaseOrderProperties}
            globalFilters={{
              'project.id': project.id,
            }}
            itemLink={(item) => `/finances/purchase-orders/${item.id}/show`}
          />
        </Card>
      </GridItem>
    </Grid>
  );
};

export default FinancesView;
