import React, { FunctionComponent } from 'react';
import TablePage from '../../../../../../components/organisms/TablePage/TablePage';
import inventoryItemTableFilters from './InventoryItemTable.filters';
import inventoryItemTableProperties from './InventoryItemTable.properties';
import InventoryItemExtendedRow from '../../../../../../components/molecules/ExtendedRow/InventoryItemExtendedRow/InventoryItemExtendedRow';
import InventoryItemScreenHeadingRight from './InventoryItemScreenHeadingRight';
import InventoryItemType from '../../../../../../@types/Inventory/InventoryItemType';

type Props = {};

const InventoryItemScreen: FunctionComponent<Props> = (props) => {
  const [selectedItems, setSelectedItems] = React.useState<InventoryItemType[]>([]);
  return (
    <TablePage
      url={'/api/inventory_items'}
      context={'/api/inventory_items'}
      onSelect={setSelectedItems}
      filters={inventoryItemTableFilters}
      properties={inventoryItemTableProperties}
      heading={'Inventory'}
      headingRight={<InventoryItemScreenHeadingRight selectedItems={selectedItems} />}
      extendedRow={InventoryItemExtendedRow}
    />
  );
};

export default InventoryItemScreen;
