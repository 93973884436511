import React, { FunctionComponent } from 'react';
import BetterChip, { ChipColor } from '../../atoms/Chips/BetterChip';
import { EstimateStatusDict, EstimateType } from '../../../@types/Estimate/EstimateType';
import { DictValue } from '../../../@types/Dictionary/DictValue';
import { PiCircleHalfBold, PiHandPalmBold, PiPencilBold, PiStarBold, PiXSquareBold } from 'react-icons/pi';

type Props = {
  status: EstimateType['status'];
};

const EstimateStatusTag: FunctionComponent<Props> = ({ status }) => {
  return (
    <BetterChip icon={icon(status)} color={color(status)}>
      {translateStatus(status)}
    </BetterChip>
  );
};

const translateStatus = (status: EstimateType['status']): string => {
  return EstimateStatusDict.find((dict: DictValue) => dict.value === status)?.label || status;
};
const color = (status: EstimateType['status']): ChipColor => {
  switch (status) {
    case 'accepted':
      return 'success';
    case 'in_progress':
      return 'primary';
    case 'rejected':
      return 'error';
    case 'canceled':
      return 'neutral';
    case 'draft':
      return 'neutral';
  }
};
const icon = (status: EstimateType['status']) => {
  switch (status) {
    case 'accepted':
      return PiStarBold;
    case 'in_progress':
      return PiCircleHalfBold;
    case 'rejected':
      return PiHandPalmBold;
    case 'canceled':
      return PiXSquareBold;
    case 'draft':
      return PiPencilBold;
  }
};

export default EstimateStatusTag;
