import React, { useCallback, useEffect, useMemo, useState } from 'react';
import api from 'services/api';
import { EntityType } from '../@types/Entity/EntityType';
import { removeEmptyIds } from '../helpers/Entity';
import { AxiosError } from 'axios';
import SnackContainer from '../legacy/CapeMorris/components/snack-bar/use-snack-container';
import { ErrorResponseType } from '../@types/hydra/hydra';
import { AuthContext } from '../Context/auth-context/auth-context';

type UseEntityResponse<T> = {
  entity: T | undefined | null;
  loading: boolean;
  waiting: boolean;
  reload: () => void;
  save: (data: T) => Promise<T>;
  delete: () => void;
  create: (data: T) => Promise<T>;
  overwrite: (data: T) => void;
};

function useEntity<Entity extends EntityType>(iri: string | undefined): UseEntityResponse<Entity> {
  const { showSnack } = SnackContainer.useContainer();
  const { logout } = React.useContext(AuthContext);
  const [entity, setEntity] = useState<Entity | undefined>();
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const isEntity = useMemo<boolean>(() => {
    if (!iri) {
      return false;
    }
    const lastPart = iri.split('/').pop();
    return !!lastPart && !isNaN(parseFloat(lastPart));
  }, [iri]);

  const load = useCallback(() => {
    setEntity(undefined);
    setLoading(true);
    if (!iri || !isEntity) {
      setLoading(false);
      return;
    }
    void api.get<Entity>(iri.substring(4)).then((r) => {
      setEntity(r.data);
      setLoading(false);
    });
  }, [iri, isEntity]);

  useEffect(() => {
    load();
  }, [load]);

  const create = useCallback<(data: Entity) => Promise<Entity>>(
    //@ts-ignore
    async (data) => {
      if (iri === undefined) {
        throw new Error('Cannot create element without proper iri.');
      }
      setWaiting(true);
      removeEmptyIds(data);
      let url = iri;
      if (isEntity) {
        const parts = iri.split('/');
        parts.pop();
        url = parts.join('/');
      }

      return api
        .post<Entity | ErrorResponseType>(url, data)
        .then((response) => {
          setWaiting(false);
          showSnack({
            title: 'Success',
            message: 'Object created with success',
            type: 'success',
          });
          return response.data;
        })
        .catch((error: AxiosError<ErrorResponseType>) => {
          setWaiting(false);

          const response = error.response;
          if (response?.status === 401) {
            logout();
          }
          showSnack({
            title: 'Error',
            message: response ? response.data['hydra:description'] : '[153] Unexpected error while communicating with server',
            type: 'error',
          });
          return Promise.reject(response ? response.data['hydra:description'] : '[153] Unexpected error while communicating with server');
        });
    },
    [iri, isEntity],
  );

  const save = useCallback<(data: Entity) => Promise<Entity>>(
    //@ts-ignore
    async (data: Entity) => {
      if (iri === undefined) {
        throw new Error('Cannot save element without proper iri.');
      }
      setWaiting(true);
      removeEmptyIds(data);
      if (!isEntity) {
        throw new Error('Cannot save element without proper iri.');
      }
      return api
        .put<Entity | ErrorResponseType>(iri, data)
        .then((response) => {
          setWaiting(false);
          showSnack({
            title: 'Success',
            message: 'Object saved with success',
            type: 'success',
          });
          return response.data;
        })
        .catch((error: AxiosError<ErrorResponseType>) => {
          setWaiting(false);

          const response = error.response;
          if (response?.status === 401) {
            logout();
          }
          showSnack({
            title: 'Error',
            message: response ? response.data['hydra:description'] : '[153] Unexpected error while communicating with server',
            type: 'error',
          });
          return Promise.reject(response ? response.data['hydra:description'] : '[153] Unexpected error while communicating with server');
        });
    },
    [],
  );

  const reload = useCallback(() => {
    load();
  }, [load]);

  const overwrite = useCallback<(data: Entity) => void>(
    (data) => {
      if (iri !== data['@id']) {
        throw Error("You're trying to overwrite different objects");
      }
      setEntity(data);
    },
    [iri],
  );

  return {
    entity,
    loading,
    save,
    reload,
    delete: () => {}, //todo: define delete
    create,
    waiting,
    overwrite,
  };
}

export default useEntity;
