import { TableProperties } from '../../../@types/Table/TableProperty';
import { EstimateType } from '../../../@types/Estimate/EstimateType';
import IdTemplate from '../../molecules/CellTemplates/common/IdTemplate/IdTemplate';
import StringTemplate from '../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import EstimateStatusTemplate from './components/EstimateStatusTemplate';
import MoneyTemplate from '../../molecules/CellTemplates/common/MoneyTemplate/MoneyTemplate';
import DateTemplate from '../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import PersonTemplate from '../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import EstimateProjectsTemplate from './components/EstimateProjectsTemplate';

export const properties: TableProperties<EstimateType> = [
  {
    name: 'id',
    visibleName: 'ID',
    sortable: true,
    template: IdTemplate,
  },
  {
    name: 'status',
    visibleName: 'Status',
    sortable: true,
    template: EstimateStatusTemplate,
  },
  {
    name: 'title',
    visibleName: 'Title',
    sortable: true,
    template: StringTemplate,
  },
  {
    name: 'createdBy',
    template: PersonTemplate,
    visibleName: 'Author',
  },
  {
    name: 'client.name',
    visibleName: 'Partner',
    sortable: true,
    template: StringTemplate,
  },
  {
    name: 'projects',
    visibleName: 'Projects',
    template: EstimateProjectsTemplate,
  },
  {
    name: 'currentVersion.title',
    visibleName: 'Current Version',
    template: StringTemplate,
  },
  {
    name: 'currentVersion.createdAt',
    visibleName: 'Version date',
    sortable: 'versions.createdAt',
    template: DateTemplate,
  },
  {
    name: 'currentVersion.total',
    justifyContent: 'flex-end',
    visibleName: 'Total',
    template: MoneyTemplate,
  },
];
