import React, { FunctionComponent } from 'react';
import BetterChip, { ChipColor } from '../../atoms/Chips/BetterChip';
import { EstimateVersionStatusDict, EstimateVersionType } from '../../../@types/Estimate/EstimateType';
import { PiCircleBold, PiCopyBold, PiStarBold } from 'react-icons/pi';

type Props = {
  status: EstimateVersionType['status'];
};

const EstimateVersionStatusTag: FunctionComponent<Props> = ({ status }) => {
  return (
    <BetterChip icon={icon(status)} color={color(status)}>
      {translateStatus(status)}
    </BetterChip>
  );
};

const translateStatus = (status: EstimateVersionType['status']) => {
  return EstimateVersionStatusDict.find((e) => e.value === status)?.label;
};
const color = (status: EstimateVersionType['status']): ChipColor => {
  switch (status) {
    case 'old':
      return 'neutral';
    case 'current':
      return 'secondary';
    case 'alternative':
      return 'primary';
  }
};
const icon = (status: EstimateVersionType['status']) => {
  switch (status) {
    case 'old':
      return PiCircleBold;
    case 'current':
      return PiStarBold;
    case 'alternative':
      return PiCopyBold;
  }
};

export default EstimateVersionStatusTag;
