import React, { FunctionComponent } from 'react';
import Container from '../../../../components/atoms/Container/Container';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import EstimateVersionDetailsScreen from '../../../../components/templates/EstimateVersions/EstimateVersionDetailsScreen';
import EstimateVersionEditScreen from '../../../../components/templates/EstimateVersions/EstimateVersionEditScreen';
import EstimateVersionCreateScreen from '../../../../components/templates/EstimateVersions/EstimateVersionCreateScreen';

type Props = {};

const EstimateVersionsScreen: FunctionComponent<Props> = (props) => {
  return (
    <Container>
      <Helmet>
        <title>Estimates | F.CAPE</title>
      </Helmet>
      <Routes>
        <Route path="create" element={<EstimateVersionCreateScreen />}></Route>
        <Route path=":id/edit" element={<EstimateVersionEditScreen />}></Route>
        <Route path=":id/show" element={<EstimateVersionDetailsScreen />}></Route>
      </Routes>
    </Container>
  );
};
export default EstimateVersionsScreen;
