import React, { FunctionComponent, useCallback, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import api from '../../../../services/api';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import { AbsenceCalendarType } from '../../../../@types/Absences/AbsenceCalendarType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { color } from '../../../../styles/Variables';
import { HolidayType } from '../../../../@types/Holiday/HolidayType';
import styled from 'styled-components';
import Form from '../../../molecules/Form/Form';
import CalendarSettings, { CalendarSettingsType } from './CalendarSettings';
import IriHelper from '../../../../helpers/iri-helper';

type Props = {};

const absenceTypeDict: DictValue[] = [
  { value: 'vacation', label: 'Vacation' },
  { value: 'home_office', label: 'Home office' },
  { value: 'sick_leave', label: 'Sick leave' },
];

const translateType = (value: string): string => {
  return absenceTypeDict.find((a) => a.value === value)?.label || value;
};

const colorByType = (type: string): string => {
  switch (type) {
    case 'vacation':
      return color.primary['60'];
    case 'home_office':
      return color.primary['40'];
    case 'sick_leave':
      return color.neutral['50'];
    default:
      return 'black';
  }
};

const CalendarView: FunctionComponent<Props> = (props) => {
  const [settings, setSettings] = useState<CalendarSettingsType>({
    showHomeOffices: true,
    department: undefined,
  });

  const updateSettings = useCallback((settings: Partial<CalendarSettingsType>) => {
    setSettings((s) => ({ ...s, ...settings }));
  }, []);

  return (
    <StyledCalendarView>
      <Form onSubmit={() => {}}>
        <CalendarSettings settings={{ showHomeOffices: true }} onUpdate={updateSettings} />
      </Form>
      <FullCalendar
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5],
        }}
        firstDay={1}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        dayMaxEvents={5}
        events={async (t: any) => {
          const promises = [
            api
              .get<ArrayResponseType<AbsenceCalendarType>>('/absences-by-month', {
                params: {
                  dateFrom: t.startStr,
                  dateTo: t.endStr,
                  department: IriHelper.iriToId(settings.department),
                },
              })
              .then((r) =>
                r.data['hydra:member']
                  .filter((a) => (settings.showHomeOffices ? true : a.type !== 'home_office'))
                  .map((a) => ({
                    title: `${a.person.name} (${translateType(a.type)})`,
                    start: a.dateFrom,
                    end: a.dateTo,
                    color: colorByType(a.type),
                  })),
              ),
            api
              .get<ArrayResponseType<HolidayType>>('/holidays', {
                params: {
                  'date[after]': t.startStr,
                  'date[before]': t.endStr,
                },
              })
              .then((r) =>
                r.data['hydra:member'].map((a) => ({
                  title: a.name,
                  start: a.date,
                  allDay: true,
                  eventColor: 'red',
                  display: 'background',
                  color: color.additionals.purple,
                })),
              ),
          ];
          return Promise.all(promises).then((r) => r.flat());
        }}
      />
    </StyledCalendarView>
  );
};

const StyledCalendarView = styled.div`
  .fc-bg-event > .fc-event-title {
    color: ${color.white};
  }
`;

export default CalendarView;
