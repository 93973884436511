import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import api from '../../../services/api';
import { ArrayResponseType } from '../../../@types/hydra/hydra';
import { ResourceType } from '../../../@types/Resource/ResourceType';
import * as Styled from './Resources.styled';
import AddResourceButton from './components/AddResourceButton';
import Resource from './components/Resource';
import Text from '../../atoms/Text/Text';
import { FileContextType } from '../../../@types/File/File';

type Props = {
  disableCreation?: boolean;
  subject?: string;
  subjectId?: string | number;
  editable?: ((resource: ResourceType) => boolean) | boolean;
  customResources?: ResourceType[];
  maxWidth?: boolean;
  reloadToken?: number;
  noResourcesText?: string;
  context?: FileContextType;
};

const Resources: FunctionComponent<Props> = ({
  subject,
  subjectId,
  disableCreation = false,
  editable,
  customResources = [],
  maxWidth,
  reloadToken,
  noResourcesText,
  context,
}) => {
  const [resources, setResources] = useState<ResourceType[]>([]);

  const loadResources = useCallback(() => {
    api
      .get<ArrayResponseType<ResourceType>>('/resources', {
        params: {
          subject,
          subjectId,
        },
      })
      .then((response) => {
        setResources(response.data['hydra:member']);
      });
  }, [subject, subjectId]);

  const handleCreate = (resource: ResourceType) => {
    resource.new = true;
    setResources((resources) => [...resources, resource]);
  };

  const handleDelete = useCallback((deleted: ResourceType) => {
    setResources((resources) => resources.filter((resource) => resource.id !== deleted.id));
  }, []);

  useEffect(() => {
    if (!subjectId) {
      return;
    }
    loadResources();
  }, [reloadToken, subjectId, subject]);

  const addCustomResources = useCallback(() => {
    return (
      <>
        {customResources.length !== 0 &&
          customResources.map((resource, index) => <Resource resource={resource} key={index} onDelete={handleDelete} editable={false} />)}
      </>
    );
  }, [customResources]);

  return (
    <Styled.Container>
      {!disableCreation && subject && subjectId && (
        <div>
          <AddResourceButton context={context} subject={subject} subjectId={subjectId} onCreate={handleCreate} />
        </div>
      )}
      <Styled.List $maxWidth={maxWidth ?? false}>
        {addCustomResources()}
        {resources.map((resource) => (
          <Resource resource={resource} key={resource.id} onDelete={handleDelete} editable={editable} />
        ))}
        {resources.length === 0 && customResources?.length === 0 && noResourcesText && <Text>{noResourcesText}</Text>}
      </Styled.List>
    </Styled.Container>
  );
};

export default Resources;
