import React, { FunctionComponent } from 'react';
import EstimateVersionForm from './components/EstimateVersionForm';
import { useLocation } from 'react-router-dom';
import { EstimateType } from '../../../@types/Estimate/EstimateType';

const EstimateVersionCreateScreen: FunctionComponent = () => {
  const { state }: { state: { estimate: EstimateType } } = useLocation();

  return <EstimateVersionForm currency={state.estimate.currency} estimateIri={state.estimate['@id']} estimateTitle={state.estimate.title} />;
};
export default EstimateVersionCreateScreen;
