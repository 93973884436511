import React from 'react';
import { CellTemplate } from '../../../molecules/CellTemplates/CellTemplate';
import { EstimateType } from '../../../../@types/Estimate/EstimateType';
import EmptyCell from '../../../molecules/CellTemplates/common/EmptyCell';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import { shorten } from '../../../../helpers/shorten';
import { fontWeight } from '../../../../styles/Variables';
import Text from '../../../atoms/Text';

type Props = {};

const EstimateProjectsTemplate: CellTemplate<EstimateType['projects']> = ({ value, item }) => {
  return (
    <>
      {!value || value.length === 0 ? (
        <EmptyCell />
      ) : (
        <>
          <div>
            <Colored style={{ fontWeight: fontWeight.bold }}>#{value[0].id}</Colored> {shorten(value[0].name!, 25, 25)}
          </div>
          {value.length > 1 && (
            <Text color={'semiGrey'} size={'xs'}>
              +{value.length - 1} more
            </Text>
          )}
        </>
      )}
    </>
  );
};
export default EstimateProjectsTemplate;
