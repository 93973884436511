import React from 'react';
import { BasicProjectType } from '../../../../../@types/Project/Project';
import ProjectStatus from '../../../ProjectStatus/ProjectStatus';
import { CellTemplate } from '../../CellTemplate';
import { NonBillableDetailDict } from '../../../../../@dicts/Project/NonBillableDetailDict';
import BetterChip from '../../../../atoms/Chips/BetterChip';
import { PiStarBold } from 'react-icons/pi';

const ProjectStatusTemplate: CellTemplate<BasicProjectType['status'], {}, BasicProjectType> = ({ value, item }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem', alignItems: 'flex-start' }}>
      <ProjectStatus status={value} archived={item.archived} />

      {!item.billable && (
        <div>
          <BetterChip color={'success'}>{NonBillableDetailDict.find((v) => v.value === item.nonBillableDetail)?.label}</BetterChip>
        </div>
      )}
      {item.hasAcceptedEstimate && (
        <BetterChip color={'success'} icon={PiStarBold}>
          Est. accpeted
        </BetterChip>
      )}
    </div>
  );
};

export default ProjectStatusTemplate;
