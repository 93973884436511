import React, { FunctionComponent } from 'react';
import Container from '../../../../components/atoms/Container/Container';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import EstimatesListScreen from '../../../../components/templates/Estimates/EstimatesListScreen';
import EstimatesCreateScreen from '../../../../components/templates/Estimates/EstimatesCreateScreen';
import EstimatesEditScreen from '../../../../components/templates/Estimates/EstimatesEditScreen';
import EstimatesDetailsScreen from '../../../../components/templates/Estimates/EstimatesDetailsScreen';

const EstimatesScreen: FunctionComponent = () => {
  return (
    <Container>
      <Helmet>
        <title>Estimates | F.CAPE</title>
      </Helmet>
      <Routes>
        <Route path="" element={<EstimatesListScreen />}></Route>
        <Route path="create" element={<EstimatesCreateScreen />}></Route>
        <Route path=":id/edit" element={<EstimatesEditScreen />}></Route>
        <Route path=":id/show" element={<EstimatesDetailsScreen />}></Route>
      </Routes>
    </Container>
  );
};
export default EstimatesScreen;
