import React, { FunctionComponent, useCallback } from 'react';
import TablePage from '../../organisms/TablePage/TablePage';
import { useLocation, useNavigate } from 'react-router-dom';
import { properties } from './EstimatesListScreen.properties';
import EstimateExtendedRow from './components/EstimateExtendedRow';
import { filters } from './EstimatesListScreen.filters';

type Props = {};

const EstimatesListScreen: FunctionComponent<Props> = (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleCreate = useCallback(() => {
    navigate(`${pathname}/create`);
  }, []);

  return (
    <>
      <TablePage
        url={'/estimates'}
        context={'/api/estimates|Estimates'}
        properties={properties}
        filters={filters}
        heading={'Estimates'}
        onCreate={handleCreate}
        extendedRow={EstimateExtendedRow}
        itemLink={(item) => `${pathname}/${item.id}/show`}
      />
    </>
  );
};
export default EstimatesListScreen;
