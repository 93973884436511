import React, { FunctionComponent, useEffect } from 'react';
import { SwitchInput } from '../../../molecules/Form/SwitchInput/SwitchInput';
import { useWatch } from 'react-hook-form';
import DepartmentSelect from '../../Workload/components/DepartmentSelect';

export type CalendarSettingsType = {
  showHomeOffices: boolean;
  department?: string;
};

type Props = {
  settings: CalendarSettingsType;
  onUpdate: (settings: Partial<CalendarSettingsType>) => void;
};

const CalendarSettings: FunctionComponent<Props> = ({ settings, onUpdate }) => {
  const showHomeOffices = useWatch({ name: 'showHomeOffices' });
  const department = useWatch({ name: 'department' });

  useEffect(() => {
    onUpdate({ department });
  }, [department]);

  useEffect(() => {
    onUpdate({ showHomeOffices });
  }, [showHomeOffices]);

  return (
    <div style={{ display: 'flex', gap: '2.6rem', alignItems: 'end', marginBottom: '1.6rem' }}>
      <div style={{ width: 200 }}>
        <DepartmentSelect onDepartmentSelect={() => {}} placeholder={'Department'} required={false} />
      </div>
      <SwitchInput label={'Show home offices'} name={'showHomeOffices'} defaultValue={settings.showHomeOffices} />
    </div>
  );
};
export default CalendarSettings;
