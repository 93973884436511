import React, { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useFormError from '../../../../hooks/Form/useFormError';
import { InputProps } from '../../../../@types/Form/InputProps';
import { FormInputContainer, FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import FormError from '../../../atoms/Form/FormError/FormError';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { selectStyles } from './select.styles';
import AsyncSelect from 'react-select/async';
import api from '../../../../services/api';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import { EntityType } from '../../../../@types/Entity/EntityType';
import { OnChangeValue } from 'react-select';

type Props = InputProps & {
  baseUrl: string;
  baseUrlParams?: { [key: string]: string | number | string[] | undefined };
  // eslint-disable-next-line
  optionComponent?: ComponentType<any>;
  // eslint-disable-next-line
  singleValueComponent?: ComponentType<any>;
  optionLabel?: string;
  isOptionDisabled?: (option: DictValue) => boolean;
  defaultOptions?: boolean;
};

/**
 * Component designed to be single select
 */
const DynamicSelectInput: FunctionComponent<Props> = ({ baseUrl, defaultOptions = true, ...props }) => {
  const { control, watch } = useFormContext();
  const error = useFormError(props.name);
  const optionLabel = props.optionLabel ? props.optionLabel : 'name';
  const [init, setInit] = useState(false);
  const watchValue = watch(props.name);
  // eslint-disable-next-line
  const [internalValue, setInternalValue] = useState<any | undefined>();

  const handleDefaultValue = useCallback((uri: string) => {
    api.get<EntityType>(uri).then((response) => {
      setInternalValue(response.data);
      setInit(true);
    });
  }, []);

  useEffect(() => {
    if (typeof watchValue === 'string') {
      return;
    }

    setInternalValue(watchValue);
  }, [watchValue]);

  useEffect(() => {
    if (init) {
      return;
    }
    if (watchValue && typeof watchValue === 'string' && watchValue.startsWith('/api/')) {
      handleDefaultValue(watchValue);
    } else if (typeof watchValue === 'object') {
      setInternalValue(watchValue);
      setInit(true);
    } else {
      if (props.defaultValue) {
        if (typeof props.defaultValue === 'string' && props.defaultValue.startsWith('/api/')) {
          handleDefaultValue(props.defaultValue);
        } else {
          setInternalValue(props.defaultValue);
          setInit(true);
        }
      } else {
        setInit(true);
      }
    }
  }, [watchValue, init]);

  const components = useMemo(() => {
    // eslint-disable-next-line
    const result: any = {
      IndicatorSeparator: () => null,
    };
    if (props.optionComponent) {
      result.Option = props.optionComponent;
    }
    if (props.singleValueComponent) {
      result.SingleValue = props.singleValueComponent;
    }
    return result;
  }, [props.optionComponent, props.singleValueComponent]);

  const loadOptions = (inputValue: string, callback: Function) => {
    if (!baseUrl) {
      callback([]);
      return;
    }
    const params: { [key: string]: string | number | string[] | undefined } = props.baseUrlParams ? props.baseUrlParams : {};
    params['_search'] = inputValue;

    api.get<ArrayResponseType>(baseUrl, { params }).then((response) => {
      callback(
        response.data['hydra:member'].map((i) => {
          return { value: i['@id'], label: i[optionLabel], meta: i };
        }),
      );
    });
  };

  return (
    <>
      <FormInputContainer>
        <FormLabel status={error && 'error'} required={!!props.required} disabled={!!props.disabled}>
          {props.label}
        </FormLabel>
        {init && (
          <Controller
            disabled={props.disabled}
            control={control}
            defaultValue={props.defaultValue}
            name={props.name}
            rules={{
              required: props.required ? 'this field is required' : undefined,
            }}
            render={({ field: { onChange } }) => (
              <AsyncSelect
                menuPortalTarget={document.getElementById('data-picker-portal')}
                key={1}
                loadOptions={loadOptions}
                defaultOptions={defaultOptions}
                value={internalValue ? { meta: internalValue, value: internalValue['@id'], label: internalValue[optionLabel] } : null}
                onChange={(option: OnChangeValue<DictValue, false>) => {
                  if (option) {
                    props.onChange && props.onChange(option);
                    onChange(option.value);
                    setInternalValue(option.meta);
                  } else {
                    props.onChange && props.onChange(null);
                    onChange(null);
                    setInternalValue(undefined);
                  }
                }}
                required={!!props.required}
                isDisabled={props.disabled}
                isOptionDisabled={props.isOptionDisabled}
                isMulti={false}
                placeholder={props.placeholder}
                components={components}
                noOptionsMessage={() => 'Start typing to search...'}
                isClearable={!props.required}
                styles={selectStyles(!!error, props.required, props.disabled)}
              />
            )}
          ></Controller>
        )}
      </FormInputContainer>
      <FormError name={props.name} />
    </>
  );
};

export default DynamicSelectInput;
