import React, { FunctionComponent } from 'react';
import DepartmentSelect from './DepartmentSelect';
import { Icon } from '../../../atoms/Icon/Icon';
import CalendarIcon from '../../../../Icons/Calendar.icon';
import { ArrowIcon } from '../../../../Icons/arrow.icon';
import { DepartmentType } from '../../../../@types/Department/DepartmentType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';

type Props = {
  onDepartmentSelect: (data: DictValue<DepartmentType>) => void;
  onChangeWeek: (directionNumber: number) => void;
  placeholder: string;
};

const WorkloadActions: FunctionComponent<Props> = ({ onDepartmentSelect, onChangeWeek, placeholder }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1.6rem' }}>
      <DepartmentSelect onDepartmentSelect={onDepartmentSelect} placeholder={placeholder} />
      <Icon size={2} onClick={() => onChangeWeek(0)}>
        <CalendarIcon />
      </Icon>
      <Icon size={1.8} onClick={() => onChangeWeek(-1)}>
        <ArrowIcon direction={'left'} />
      </Icon>
      <Icon size={1.8} onClick={() => onChangeWeek(1)}>
        <ArrowIcon direction={'right'} />
      </Icon>
    </div>
  );
};

export default WorkloadActions;
