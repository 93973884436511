import React, { FunctionComponent } from 'react';
import { DashboardData } from '../../../../../../@types/Dashboard/DashboardDataType';
import styled from 'styled-components';
import { color, fontWeight } from '../../../../../../styles/Variables';
import { PiTimerBold } from 'react-icons/pi';
import DashboardCard from '../DashboardCard';
import { Icon } from '../../../../../atoms/Icon/Icon';
import ProgressBar from '../../../../../molecules/TimeProgress/ProgressBar/ProgressBar';

type Props = {
  data?: DashboardData['timesheetSummary'];
};

const TimeshetSummary: FunctionComponent<Props> = ({ data }) => {
  if (!data) return;
  const percents = `${Math.round(data.loggedHoursPercentage * 100)}%`;
  const sentimentColor =
    data.loggedHoursPercentage >= 1 ? color.secondary[80] : data.loggedHoursPercentage >= 0.8 ? color.semantic.warning['80'] : color.semantic.error['80'];
  return (
    <DashboardCard
      title={'Timesheet'}
      icon={
        <Icon color={color.primary['60']} size={2.3}>
          <PiTimerBold />
        </Icon>
      }
    >
      <Percentage style={{ color: sentimentColor }}>{percents}</Percentage>
      <ProgressBar current={data.loggedHoursPercentage} estimate={1} color={sentimentColor} />
    </DashboardCard>
  );
};

const Percentage = styled.div`
  font-weight: ${fontWeight.extraBold};
  font-size: 4rem;
  text-align: right;
`;

export default TimeshetSummary;
