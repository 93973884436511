import React, { FunctionComponent, useState } from 'react';
import { EstimateVersionType } from '../../../../@types/Estimate/EstimateType';
import styled from 'styled-components';
import DateString from '../../../atoms/DateString/DateString';
import MoneyAsString from '../../../../helpers/moneyAsString';
import { color, fontSize, fontWeight } from '../../../../styles/Variables';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import EstimateVersionStatusTag from '../../../molecules/Estimate/EstimateVersionStatusTag';
import Person from '../../../molecules/Person/Person';
import { PiCopyBold, PiStarBold, PiTrashBold } from 'react-icons/pi';
import useApi from '../../../../hooks/useApi';
import { useEntityContext } from '../../../../Context/EntityContext/EntityContext';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../../molecules/DeleteModal/DeleteModal';

type Props = {
  version: EstimateVersionType;
  editable: boolean;
};

const EstimateDetailsVersion: FunctionComponent<Props> = ({ version, editable }) => {
  const { post, del, put } = useApi();
  const { reload } = useEntityContext();
  const navigate = useNavigate();
  const [confirmDeletion, setConfirmDeletion] = useState(0);
  const duplicateVersion = async () => {
    return post(`/estimate_versions/${version.id}/duplicate`, {}).then(() => reload());
  };
  const deleteVersion = async () => {
    return del(`/estimate_versions/${version.id}`).then(() => reload());
  };
  const promoteToCurrent = async () => {
    return put(`/estimate_versions/${version.id}`, {
      status: 'current',
    }).then(() => reload());
  };
  return (
    <StyledVersion onClick={() => navigate(`/finances/estimate-versions/${version.id}/show`)}>
      <div style={{ fontWeight: fontWeight.bold }}>
        <Colored>{version.title}</Colored>
        <EstimateVersionStatusTag status={version.status} />
      </div>
      <div>
        <DateString input={version.createdAt} />
      </div>
      <div style={{ textAlign: 'right', paddingRight: '4rem' }}>
        <MoneyAsString {...version.total} />
      </div>
      <div style={{ display: 'flex', fontSize: fontSize.body.l }}>
        <Person person={version.createdBy} />
        &nbsp;at <DateString input={version.createdAt} />
      </div>
      {editable && (
        <div
          style={{
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {version.status !== 'current' && (
            <StyledAction title={'Promote to current'} onClick={promoteToCurrent}>
              <PiStarBold />
            </StyledAction>
          )}
          <StyledAction title={'Duplicate'} onClick={duplicateVersion}>
            <PiCopyBold />
          </StyledAction>
          {version.status !== 'current' && (
            <StyledDeleteAction title={'Delete'} onClick={() => setConfirmDeletion((p) => p + 1)}>
              <PiTrashBold />
            </StyledDeleteAction>
          )}
          <DeleteModal show={confirmDeletion} onConfirm={() => deleteVersion()} title={`Delete version ${version.title}?`} />
        </div>
      )}
    </StyledVersion>
  );
};

const StyledAction = styled.div`
  cursor: pointer;
  border: 1px solid ${color.neutral['40']};
  border-radius: 4px;
  transition: all 0.2s;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;

  &:hover {
    color: ${color.primary['60']};
  }
`;
const StyledDeleteAction = styled(StyledAction)`
  &:hover {
    color: ${color.semantic.error['100']};
  }
`;

const StyledVersion = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  border: 1px solid ${color.neutral['40']};
  padding: 1.6rem;
  border-radius: 0.8rem;
  font-size: 1.1em;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export default EstimateDetailsVersion;
