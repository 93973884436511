import React from 'react';
import ExtendedRowComponent from '../../../atoms/Table/ExtendedRowComponent';
import { EstimateType } from '../../../../@types/Estimate/EstimateType';
import TableCell from '../../../atoms/Table/TableCell';
import moneyAsString from '../../../../helpers/moneyAsString';
import BetterChip from '../../../atoms/Chips/BetterChip';
import { color } from '../../../../styles/Variables';
import dateString from '../../../atoms/DateString/DateString';
import DateString from '../../../atoms/DateString/DateString';

type Props = {};

const EstimateExtendedRow: ExtendedRowComponent<EstimateType> = ({ entity }) => {
  return (
    <>
      {entity.versions
        // .filter((version) => version.status !== 'current')
        .map((version, idx) => (
          <tr key={idx} style={{ backgroundColor: color.neutral[20] }}>
            <TableCell colSpan={7}> </TableCell>

            <TableCell colSpan={1}>
              {version.title}&nbsp;&nbsp;&nbsp;<BetterChip>{version.status}</BetterChip>
            </TableCell>
            <TableCell colSpan={1}>
              <DateString input={version.createdAt} />
            </TableCell>
            <TableCell colSpan={1} justifyContent={'flex-end'}>
              {moneyAsString(version.total)}
            </TableCell>
          </tr>
        ))}
    </>
  );
};
export default EstimateExtendedRow;
