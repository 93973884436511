import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import useEntity from '../../../hooks/useEntity';
import { Loader } from '../../../legacy/CapeMorris/components';
import EstimateForm from './components/EstimateForm';
import { EstimateType } from '../../../@types/Estimate/EstimateType';

const EstimatesEditScreen: FunctionComponent = () => {
  let { id } = useParams<{ id: string }>();
  const { entity, loading } = useEntity<EstimateType>(`/api/estimates/${id}`);

  return (
    <>
      {loading && <Loader />}
      {entity && <EstimateForm estimate={entity} />}
    </>
  );
};
export default EstimatesEditScreen;
