import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import EntityProvider from '../../../Context/EntityContext/EntityProvider';
import { Loader } from '../../../legacy/CapeMorris/components';
import { EstimateType } from '../../../@types/Estimate/EstimateType';
import EstimateDetailsInner from './components/EstimateDetailsInner';

const EstimatesDetailsScreen: FunctionComponent = () => {
  let { id } = useParams<{ id: string }>();

  return (
    <EntityProvider<EstimateType> url={`/api/estimates/${id}`}>
      {({ loading, entity }) => (
        <>
          {loading && <Loader />}
          {entity && <EstimateDetailsInner estimate={entity} />}
        </>
      )}
    </EntityProvider>
  );
};
export default EstimatesDetailsScreen;
