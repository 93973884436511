import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import useEntity from '../../../hooks/useEntity';
import { EstimateVersionType } from '../../../@types/Estimate/EstimateType';
import { Loader } from '../../../legacy/CapeMorris/components';
import EstimateVersionForm from './components/EstimateVersionForm';

const EstimateVersionEditScreen: FunctionComponent = () => {
  let { id } = useParams<{ id: string }>();
  const { entity, loading } = useEntity<EstimateVersionType>(`/api/estimate_versions/${id}`);

  return (
    <>
      {loading && <Loader />}
      {entity && <EstimateVersionForm version={entity} />}
    </>
  );
};
export default EstimateVersionEditScreen;
