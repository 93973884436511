import { TaskListType, TaskType } from '../@types/Task/TaskType';
import api from '../services/api';
import { ArrayResponseType } from '../@types/hydra/hydra';
import qs from 'qs';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import axios from 'axios';
import serverEvents from '../services/serverEvents';

type FiltersType = Record<string, boolean | string | number | string[] | number[]> | null;

type TasksProviderReturnType = {
  data: TaskListType[] | undefined;
  count: number | undefined;
  isLoading: boolean;
};

const createUrl = (url: string, filters: FiltersType = null) => {
  if (!filters) {
    return url;
  }
  return `${url}?${qs.stringify(filters)}`;
};

const fetchData = async (filters: FiltersType = null, signal?: AbortSignal, customUrl?: string) => {
  const _url = createUrl(customUrl ?? '/api/tasks', {
    ...filters,
    _archived: 0,
  });
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const promise = api.get<ArrayResponseType>(_url, { cancelToken: source.token }).then((response) => {
    return response.data;
  });

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by TanStack Query');
  });

  return promise;
};

function useTasksProvider(ctx: string, filters: FiltersType = null, customUrl?: string, refetchOnWindowFocus = true): TasksProviderReturnType {
  const [internalData, setInternalData] = useState<TaskListType[]>([]);
  const { data, isLoading } = useQuery<ArrayResponseType<TaskListType>>([ctx, filters], ({ signal }) => fetchData(filters, signal, customUrl), {
    keepPreviousData: true,
    refetchOnWindowFocus: refetchOnWindowFocus,
  });
  const client = useQueryClient();

  useEffect(() => {
    if (data) {
      setInternalData(data['hydra:member']);
    }
  }, [data]);

  useEffect(() => {
    const listener = serverEvents.listen<TaskType>('/api/tasks').subscribe((task) => {
      const foundIndex = internalData.findIndex((t) => t['@id'] === task['@id']);
      if (foundIndex === -1 || foundIndex === undefined) {
        return;
      }
      setInternalData((prev) => {
        const clone = [...prev];
        clone.splice(foundIndex, 1, task);
        return clone;
      });
    });
    return () => {
      listener.unsubscribe();
    };
  }, [internalData]);

  useEffect(() => {
    return () => {
      client.cancelQueries({
        queryKey: [ctx],
      });
    };
  }, []);

  return {
    data: internalData ?? undefined,
    count: data ? data['hydra:totalItems'] : undefined,
    isLoading,
  };
}

export default useTasksProvider;
