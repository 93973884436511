import React from 'react';
import { ActiveLink, Container, Links } from './menu.styled';
import { MenuItemProps } from './MenuItem';
import { Link, useLocation } from 'react-router-dom';
import { MenuLink } from './MenuItem.styled';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import { color } from '../../../../../styles/Variables';
import useModal from '../../../../../hooks/useModal';
import ImpersonateModal from '../ImpersonateModal/ImpersonateModal';
import DarkModeSwitch from '../../../../../components/molecules/DarkModeSwitch/DarkModeSwitch';

type MenuProps = {
  items: React.ReactElement<MenuItemProps>[];
  linkOnMouseEnter: (item: React.ReactElement<MenuItemProps>) => void;
  linkOnClick: (item: React.ReactElement<MenuItemProps>) => void;
};

export const Menu: React.FC<MenuProps> = ({ items, linkOnMouseEnter, linkOnClick }) => {
  const location = useLocation();
  const { logout, state: user, can, isImpersonating, loadUser } = React.useContext(AuthContext);
  const { showModal } = useModal();

  const [renderItems, setRenderItems] = React.useState(items);

  const openImpersonateModal = () => {
    showModal({
      title: 'Select person to impersonate',
      body: <ImpersonateModal />,
    });
  };

  React.useEffect(() => {
    let newItems = items.map((item) => {
      if (
        (item.props.module.to === '/' && location.pathname === item.props.module.to) ||
        (item.props.module.to !== '/' && location.pathname.startsWith(item.props.module.to))
      ) {
        return (
          <Link key={item.props.module.name} to={item.props.module.to}>
            <ActiveLink key={item.props.module.name} onMouseEnter={() => linkOnMouseEnter(item)} onClick={() => linkOnClick(item)}>
              {item}
            </ActiveLink>
          </Link>
        );
      }
      return (
        <Link
          style={{ color: color.neutral[80] }}
          onClick={() => linkOnClick(item)}
          key={item.props.module.name}
          to={item.props.module.to}
          onMouseEnter={() => linkOnMouseEnter(item)}
        >
          <>{item}</>
        </Link>
      );
    });
    if (user.context === 'employee') {
      newItems.push(
        <div key={'mode-switch'} style={{ marginTop: 'auto', color: color.neutral[80] }}>
          <DarkModeSwitch />
          <Link key="profile" to={'/profile'}>
            <MenuLink>Profile</MenuLink>
          </Link>
        </div>,
      );
    }
    newItems.push(
      <Link
        key="logout"
        to={'/'}
        style={{
          color: color.neutral[80],
          marginTop: user.context === 'employee' ? 0 : 'auto',
        }}
        onClick={(e) => {
          e.preventDefault();
          logout();
        }}
      >
        <MenuLink>Logout</MenuLink>
      </Link>,
    );
    if (can('ROLE_ALLOWED_TO_SWITCH')) {
      newItems.push(
        <Link
          key="impersonate"
          to={'/'}
          style={{
            color: color.additionals.purple,
          }}
          onClick={(e) => {
            e.preventDefault();
            openImpersonateModal();
          }}
        >
          <MenuLink>Impersonate</MenuLink>
        </Link>,
      );
    }
    if (isImpersonating) {
      newItems.push(
        <Link
          key="_impersonate"
          to={'/'}
          style={{
            color: color.additionals.purple,
          }}
          onClick={(e) => {
            e.preventDefault();
            sessionStorage.removeItem('impersonate');
            loadUser(undefined, true);
          }}
        >
          <MenuLink>Mischief managed</MenuLink>
        </Link>,
      );
    }
    setRenderItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, items, linkOnClick, linkOnMouseEnter, user]);

  return (
    <Container>
      <Links style={{ zIndex: 10 }}>{renderItems}</Links>
    </Container>
  );
};
