import { BankType } from './BankType';
import { AddressType } from './AddressType';
import { FileType } from '../../File/File';
import { EntityType } from '../../Entity/EntityType';
import { ClientType } from '../../Project/Client/ClientType';
import { FinanceStatusType } from '../FinanceStatusType';
import { RequestType } from '../../Request/RequestType';
import { BranchType } from '../../Branch/BranchType';
import { BasicEmployeeType } from '../../Employee/EmployeeType';

export const ContractorSubject = 'Finances::Contractor';

export type ContractorType = EntityType & {
  name: string;
  type: 'person' | 'company';
  status: FinanceStatusType;
  firstName?: string;
  lastName?: string;
  createdAt: string;
  id: string;
  taxNumber?: string | null;
  bank: BankType;
  address: AddressType;
  email: string;
  phone: string;
  representative?: string;
  birthDate?: string | Date;
  pesel?: string;
  taxOffice?: string;
  documentType?: string;
  documentNumber?: string;
  documentValidTo?: string | Date;
  outsideEuropeanUnion: boolean;
  subject: string;
  subjectId: string | number;
  client?: ClientType | null;
  files: {
    file: FileType;
    id: string;
  }[];
  uploadedResources?: (number | string)[];
  requests: RequestType[];
  branch: BranchType;
  note?: string;
  contractTags: string[];
  lastSigner?: BasicEmployeeType | null;
  signedAt?: string | null;
  createdBy: BasicEmployeeType;
};
